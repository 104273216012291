/* Chatbot.css */

.chatbotIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

.chatbotIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  border-style: solid;
  border-width: 4px;
  border-color: #7e22ce;
}

.chatbox {
  position: fixed;
  bottom: 110px;
  right: 50px;
  width: 300px;
  height: 400px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-color: #737373;
}

.chatboxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #7e22ce;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chatboxContent {
  flex: 1;
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.chatboxInput {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
}

.chatboxInput input {
  flex: 1 ;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chatboxInput .input-btn {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #7e22ce;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  max-width: 30px;
}