.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #a855f7, #7e22ce);
  color: #fff;
  padding: 4px 10px;
}

.headerIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.headerIcon img {
  height: 100%;
  width: 100%;
}

.toolBar {
  background-color: rgb(241, 241, 241);
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-style: solid;
  border-bottom-width: 2px;
  border-color: #7e22ce;
}

.button {
  color: #003f5c;
  padding: 2px 15px;
  border-radius: 5px;
  margin-right: 5px;
  border: 2px solid #003f5c;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button div {
  margin-left: 5px;
}

.toolBar :hover {
  background-color: #003f5c;
  color: white;
}

.title {
  font-size: 1.2rem;
  font-weight: 900;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.main {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.codeSection {
  display: flex;
  flex-direction: column;
  width: 35%;
  min-width: 300px;
  overflow: hidden;
}

.editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
}

.codeHeading {
  display: flex;
  justify-content: space-between;
  background-color: lightgray;
  align-items: center;
  color: #fff;
  padding: 10px;
}

.codeHeader {
  color: #003f5c;
  font-size: 1rem;
  font-weight: 800;
}

.runBtn {
  border: 2px solid #003f5c;
  color: #003f5c;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.codeHeading :hover {
  color: white;
  background-color: #003f5c;
}

.runBtn div {
  margin-left: 5px;
}

.console {
  height: 200px;
  border-right: 1px solid #ccc;
}

.consoleHeader {
  background-color: lightgray;
  color: #003f5c;
  font-weight: 800;
  font-size: 1rem;
  padding: 10px;
}

.consoleOutput {
  padding: 10px;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: black;
  color: rgb(15, 198, 15);
}

.resizeHandle {
  border: 5px;
  cursor: w-resize;
  background: white;
  border-left: 2px dashed darkgray;
}

.dragArea {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1px;
  background-color: white;
  /* background-image: 
    linear-gradient(to right, lightgray 1px, transparent 1px),
    linear-gradient(to bottom, lightgray 1px, transparent 1px);
  background-size: 25px 25px; */
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      lightgray 25%,
      lightgray 26%,
      transparent 27%,
      transparent 74%,
      lightgray 75%,
      lightgray 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      lightgray 25%,
      lightgray 26%,
      transparent 27%,
      transparent 74%,
      lightgray 75%,
      lightgray 76%,
      transparent 77%,
      transparent
    );
  background-size: 50px 50px;
}

.dragItem {
  margin: 5px;
  cursor: move;
}

.contextMenu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* .componentSection {
  width: 30%;
  border-left: 1px solid #ccc;
  overflow-y: auto;
}

.components {
  padding: 10px;
} */

.componentSection {
  width: 35%;
  min-width: 300px;
  border-left: 1px solid #ccc;
  overflow-y: auto;
  padding: 10px;
}

.components {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.componentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.componentName {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.dragItem {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
