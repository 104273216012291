.header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #663463;
    color: white;
    padding: 10px;
    align-items: center;
}

.user{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.element{
    text-decoration: none;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
}

.btn{
    display: flex;
    flex-direction: row;
}

.homeBtn{
    flex: 1;
    background-color: white;
    color: darkslategray;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;
}

.simBtn{
    flex: 1;
    background-color: white;
    color: darkslategray;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;
}