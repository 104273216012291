.errorContainer {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .errorHeading {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .errorText {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .errorLink {
    display: inline-block;
    padding: 8px 16px;
    background-color: #36416A;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .errorLink:hover {
    background-color:#7385c2;
  }
  