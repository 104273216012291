.container{
    height: 100vh;
    width: 100vw;
    background-color: #663463;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
}

.login{
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}



.heading{
    color: white;
    margin: 10px;
    margin-bottom: 25px;
}

.form{
    background-color: white;
    padding: 40px;
    border: 1px solid white;
    border-radius: 20px;
}
.inputGroup{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}


.icon{
    flex: 0.1;
    margin-top: 15px;
}

.input[type="email"]{
    flex: 0.8;
    width: 100%;
    font-size: 18px;
    border-bottom: 1px solid grey;
    margin-top: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
}
.input[type="password"]{
    flex: 0.8;
    width: 100%;
    font-size: 18px;
    border-bottom: 1px solid grey;
    margin-top: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.input[type="email"]:focus{
    outline: none;
}
.input[type="password"]:focus{
    outline: none;
}

.signup{
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.link{
    color: #663463;
}

.loginbtn{
    background-color: #663463;
    font-size: 14px;
    color: white;
    border: 2px solid transparent;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.wpass{
    color: red;
}

@media (max-width: 600px) {
    .heading{
        margin-bottom: 40px;
    }
}